import React, { useEffect, useState } from "react";
import { Spin, message, Button } from "antd";
import SalesPieChart from "./Sales/Piechart";
import { getToken } from "../api/Auth/helpers";
import axios from "axios";
import { API, BEARER } from "../api/Auth/constant";
import { useAuthContext } from "../context/AuthContext";

type SimuladorDataItem = {
  attributes: {
    estado: string;
    tipo_simulacao: {
      data: {
        attributes: {
          simulador: string;
        };
      };
    };
  };
};

// Função de debounce simples
const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Navegacao: React.FC = () => {
  const [simuladorData, setSimuladorData] = useState<SimuladorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useAuthContext();



  const fetchSimuladorData = async () => {
    try {
      let params1: Record<string, any> = {};
      setIsLoading(true);
      params1 = {
        "pagination[limit]": 4000,
        "sort[0]": "id:desc",
        "populate[]": [
          "user",
          "user.equipa",
          "user.equipa.zona",
          "tipo_simulacao",
          "observacoes",
        ],
      };

      if (user?.tipo?.level === 3) {
        params1["filters[user][equipa][zona][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level === 4) {
        params1["filters[user][equipa][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level >= 5) {
        params1["filters[user][id][$eq]"] = user?.id;
      }
      // Pesquisa para o mês atual
      const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
      const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59).toISOString();
      params1["filters[createdAt][$gte]"] = startOfMonth;
      params1["filters[createdAt][$lte]"] = endOfMonth;


      // Verificação do nível do usuário
      if (user?.tipo?.level === 3) {
        params1["filters[user][equipa][zona][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level === 4) {
        params1["filters[user][equipa][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level >= 5) {
        params1["filters[user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level <= 2) {
      }

      const response = await axios.get(`${API}/simulacoes`, {
        params: params1,
        headers: { Authorization: `${BEARER} ${getToken()}` },
      });

      if (Array.isArray(response.data.data)) {
        setSimuladorData(response.data.data);
      } else {
        console.error("Dados retornados não são um array:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar simulações:", error);
      message.error("Erro ao buscar simulações.");
    } finally {
      setIsLoading(false);
    }
  };
  const tipoCounts: Record<string, number> = simuladorData.reduce(
    (acc: Record<string, number>, item: SimuladorDataItem) => {
      const tipo = item.attributes.tipo_simulacao.data.attributes.simulador;
      acc[tipo] = (acc[tipo] || 0) + 1;
      return acc;
    },
    {}
  );


  const estadoCounts: Record<string, number> = simuladorData.reduce(
    (acc: Record<string, number>, item: SimuladorDataItem) => {
      const estado = item.attributes.estado;
      acc[estado] = (acc[estado] || 0) + 1;
      return acc;
    },
    {}
  );

  const getEstadoColor = (estado: string): string => {
    const colors: Record<string, string> = {
      "Fechada": "#ff4d4f",
      "Aberta": "#ffa940",
      "Entregue": "#73d13d",
      "Reagendada": "#9254de",
      "Cancelada": "#595959",
      "Falsa": "#ffec3d",
      "Repetida": "#40a9ff",
      "Não atendeu": "#84af",
      "Outro": "#ff7a45",
    };
    return colors[estado] || "#000000";
  };

  const pieChartData = Object.entries(estadoCounts).map(([estado, count]) => ({
    name: `${estado}`,
    value: count,
    color: getEstadoColor(estado),
  }));

  const tipoEstadoCounts: Record<string, Record<string, number>> = simuladorData.reduce(
    (acc: Record<string, Record<string, number>>, item: SimuladorDataItem) => {
      const tipo = item.attributes.tipo_simulacao.data.attributes.simulador;
      const estado = item.attributes.estado;

      if (!acc[tipo]) {
        acc[tipo] = {}; // Inicializa o objeto para o tipo se não existir
      }
      acc[tipo][estado] = (acc[tipo][estado] || 0) + 1; // Incrementa a contagem para o estado do tipo
      return acc;
    },
    {}
  );

  useEffect(() => {
    // Chama a função de buscar dados sempre que searchAll muda
    if (user?.id) {
      fetchSimuladorData();
    }
  }, [user]); // Dependência para chamar a função quando searchAll muda

  const totalLeads = simuladorData.length;


  if (isLoading || !user) {
    return (
      <div className="flex items-center justify-center min-h-screen p-8 bg-gray-200">
        <div className="w-full max-w-screen-lg text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="items-center justify-center">
      <SalesPieChart data={pieChartData} tipoCount={tipoCounts} estadoCount={estadoCounts} TotalDeTodasLeads={totalLeads} tipoEstadoCount={tipoEstadoCounts} />
    </div>

  );
};

export default Navegacao;

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import axios, { AxiosError } from 'axios';
import { Spin, Select, DatePicker, Button, message } from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../context/AuthContext';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import html2canvas from 'html2canvas'; // Para capturar gráfico como imagem
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importa o plugin para exibir números
import { ChartOptions } from 'chart.js';

import './im.css'; // Importa o ficheiro CSS aqui
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const { Option } = Select;
const { RangePicker } = DatePicker;

export interface KPI {
  tipoSimulacao: string;
  estado: string;
  id: string;
  user: string
}




const SvIp = process.env.REACT_APP_SERVER_IP;
const backendApi = process.env.REACT_APP_BACKEND_API;
const Error = ({ message }: { message: string }) => <div>Error: {message}</div>;

const KPIsPage: React.FC = () => {
  const [kpis, setKPIs] = useState<KPI[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { users } = useAuthContext();
  const printRef = useRef(null);



  // Estados para controle de filtros
  const [selectedTipoSimulacao, setSelectedTipoSimulacao] = useState<string>(''); // Tipo de simulação selecionado
  const [selectedTipo, setSelectedTipo] = useState<string>(''); // Tipo selecionado
  const [filteredUsers, setFilteredUsers] = useState(users); // Utilizadores filtr
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null); // Agente selecionado
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  // Estado adicional para controle da renderização da página
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  // Função para carregar KPIs após selecionar o agente
  const getKPIs = async () => {setLoading(true);
  
    try {
      // Passo 1: Solicita a chave de segurança
      const keyResponse = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-key.php`, {
        params: {
          code: "964587147", // Código específico para solicitar a chave
        },
        headers: {
          "Content-Type": "application/json",
          "x-server-ip-for": SvIp, // Inclui o IP do servidor se necessário
        },
      });
  
      const { key } = keyResponse.data;
  
      if (!key) {
        message.error("Erro ao obter a chave de segurança!");
        setLoading(false);
        return;
      }
  
      // Passo 2: Faz a requisição para obter o URL do ficheiro JSON com a chave de segurança
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/KpisGet.php`, {
        params: {
          user_id: selectedUserId || undefined,
          start_date: startDate || undefined,
          end_date: endDate || undefined,
        },
        headers: {
          "x-server-ip-for": SvIp,
          "x-api-key": key, // Adiciona a chave recebida
        },
      });
  
      // Verifica se a resposta foi bem-sucedida
      if (response.status !== 200 || !response.data.file_url) {
        message.error("Erro ao obter a URL do ficheiro de KPIs!");
        return;
      }
  
      // Segunda requisição para buscar os dados do ficheiro JSON via serve_json.php
      const fileResponse = await axios.get(response.data.file_url, {
        headers: {
          "x-server-ip-for": SvIp,
          "x-api-key": key,
        }
      });

      const filteredData = fileResponse.data.filter((item: any) => {
        const tipoSimulacao = item.attributes?.tipo_simulacao?.data?.attributes?.simulador || '';
        const userId = item.attributes?.user?.data?.id;
        const createdAt = item.attributes?.createdAt || '';

        const matchesTipo = !selectedTipoSimulacao || tipoSimulacao === selectedTipoSimulacao;
        const matchesUser = !selectedUserId || userId === selectedUserId;
        const matchesDate =
          (!startDate || dayjs(createdAt).isAfter(startDate)) &&
          (!endDate || dayjs(createdAt).isBefore(endDate));

        return matchesTipo && matchesUser && matchesDate;
      });

      // Formatação dos dados
      const formattedKPIs = filteredData.map((item: any) => ({
        id: item.id,
        estado: item.attributes?.estado || 'Indefinido',
        tipoSimulacao: item.attributes?.tipo_simulacao?.data?.attributes?.simulador || 'Desconhecido',
        user: item.attributes?.user?.data?.attributes?.username || 'Por entregar',
      }));

      setKPIs(formattedKPIs);
      setDataLoaded(true);

    } catch (error) {
      console.error("Erro ao obter os KPIs:", error);
      message.error("Erro ao obter os KPIs!");
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async () => {
    if (printRef.current) {
      // Seleciona os elementos que devem ser ocultados durante a captura
      const elementsToHide = document.querySelectorAll('.hide-on-print');

      // Adiciona a classe para ocultar elementos
      elementsToHide.forEach(element => {
        element.classList.add('hide-for-print');
      });

      // Captura o conteúdo do printRef como um canvas
      const canvas = await html2canvas(printRef.current, {
        scale: 2,
        useCORS: true,
        backgroundColor: '#fff',
      });

      // Remove a classe de ocultação após a captura
      elementsToHide.forEach(element => {
        element.classList.remove('hide-for-print');
      });

      // Converte o canvas para uma imagem base64
      const image = canvas.toDataURL('image/png', 1.0);
      // name page kpi and user if is set on filter
      const name = selectedUserId ? users.find(user => user.id === Number(selectedUserId))?.username : 'kpi';
      const printWindow = window.open('', '_blank',); //mas com o nome kpis e user

      if (printWindow) {
        // Escreve a imagem no documento da nova janela
        printWindow.document.write('<html><head><title>Print</title></head><body style="margin:0;padding:0;">');
        printWindow.document.write(`<img id="print-image" src="${image}" style="width:100%; height: auto;"/>`);
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        // Espera o evento onload da imagem antes de chamar o print
        printWindow.document.getElementById('print-image')!.onload = () => {
          printWindow.print();
        };
      } else {
        console.error("Não foi possível abrir a janela de impressão.");
      }
    }
  };

  const handleUserChange = (value: number) => {
    setSelectedUserId(value);
    getKPIs();
  };

  const handleDateChange = (dates: any) => {
    if (dates) {
      setStartDate(dayjs(dates[0]).format('YYYY-MM-DD'));
      setEndDate(dayjs(dates[1]).format('YYYY-MM-DD'));
    } else {
      setStartDate('');
      setEndDate('');
    }
    getKPIs();
  };

  const resetFilters = () => {
    setSelectedTipoSimulacao('');
    setSelectedTipo('');
    setSelectedUserId(null);
    setStartDate('');
    setEndDate('');
    getKPIs();
  };

  useEffect(() => {
    getKPIs();
  }, [selectedTipoSimulacao, selectedTipo, selectedUserId, startDate, endDate]);

  const handleExportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('KPIs');

    // Configurar colunas
    worksheet.columns = [
      { header: 'ID', key: 'id', width: 10 },
      { header: 'Estado', key: 'estado', width: 20 },
      { header: 'Tipo de Simulação', key: 'tipoSimulacao', width: 30 },
      { header: 'Usuário', key: 'user', width: 20 },
    ];

    // Preenchendo os dados
    kpis.forEach(kpi => {
      worksheet.addRow({
        id: kpi.id,
        estado: kpi.estado,
        tipoSimulacao: kpi.tipoSimulacao,
        user: kpi.user || 'Por entregar'
      });
    });

    // Salva o arquivo Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'KPIs.xlsx';
      link.click();
    });
  };


  // Função para agrupar KPIs
  const groupKPIs = (data: KPI[]) => {
    const groupedData = data.reduce((acc, kpi) => {
      const estado = kpi.estado || 'Indefinido';
      const tipoSimulacao = kpi.tipoSimulacao || 'Desconhecido';

      if (!acc[estado]) {
        acc[estado] = {};
      }

      if (!acc[estado][tipoSimulacao]) {
        acc[estado][tipoSimulacao] = 0;
      }

      acc[estado][tipoSimulacao] += 1;

      return acc;
    }, {} as Record<string, Record<string, number>>);

    return groupedData;
  };

  // Agrupando dados para exibição
  const groupedData = groupKPIs(kpis);
  const totalKPIs = kpis.length;
  const tiposSimulacao = Array.from(new Set(kpis.map(kpi => kpi.tipoSimulacao)));

  const chartData = (() => {

    const estados = Object.keys(groupedData);

    const colors = [
      'rgba(255, 99, 132, 0.7)',  // Vermelho
      'rgba(54, 162, 235, 0.7)',  // Azul
      'rgba(75, 192, 192, 0.7)',  // Verde Água
      'rgba(255, 206, 86, 0.7)',  // Amarelo
    ];

    const generateColor = (index: number) => {
      const hue = (index * 137) % 360;
      return `hsla(${hue}, 70%, 60%, 0.7)`;
    };

    return {
      labels: estados,
      datasets: tiposSimulacao.map((tipo, index) => ({
        label: tipo,
        data: estados.map(estado => groupedData[estado][tipo] || 0),
        backgroundColor: colors[index] || generateColor(index),
        borderColor: (colors[index] || generateColor(index)).replace('0.7', '1'),
        borderWidth: 1,
      })),
    };
  })();

  // Dados para gráfico de pizza
  const pieData = {
    labels: tiposSimulacao,
    datasets: [
      {
        data: tiposSimulacao.map(tipo => kpis.filter(kpi => kpi.tipoSimulacao === tipo).length),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const handleTipoChange = (tipoId: string) => {
    setSelectedTipo(tipoId);
  };

  // Função para verificar se há algum filtro ativo
  const isFilterActive = () => {
    return selectedTipo || selectedUserId || startDate || endDate;
  };

  const chartOptions = useMemo(() => {
    const maxDataValue = Math.max(...chartData.datasets.flatMap(dataset => dataset.data)) + 5;

    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          title: {
            display: true,
            padding: 20,
            text: 'Tipos de Simulação',
          },
          display: true,
          position: 'top' as const,
          labels: {
            padding: 20,
          },
        },
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          color: '#000',
          font: {
            weight: 'bold',
            size: 12,
          },
          formatter: (value: number) => value.toLocaleString(),
        },
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          suggestedMax: maxDataValue, // Define o limite máximo com margem de 5
        },
      },
    } as ChartOptions<'bar'>;
  }, [chartData]); // Recalcula as opções apenas quando chartData muda

  if (error) {
    return <Error message={error} />;
  }

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white opacity-75">
        <Spin className="text-blue-500" />
      </div>
    );
  }
  const estadoOrdem = [
    'Fechada',
    'Reagendada',
    'Entregue',
    'Aberto',
    'Não atendeu',
    'Não vai avançar',
    'Outro',
    'Falsa',
    'Repetida',
  ];
  return (
    <div ref={printRef} className="p-8 space-y-8">
      <h1 className="text-4xl font-extrabold mb-4 text-gray-800">KPIs Dashboard</h1>

      {/* Sumário dos KPIs */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-600">Total Simulações</h2>
          <p className="text-2xl font-bold">{totalKPIs}</p>
        </div>
        {tiposSimulacao.map((tipo, index) => (
          <div
            key={index}
            className="p-4 bg-white rounded-lg shadow-md cursor-pointer hover:bg-blue-100 transition-all duration-200"
            onClick={() => {
              setSelectedTipoSimulacao(tipo); // Atualiza o filtro para o simulador clicado
            }}
          >
            <h2 className="text-lg font-semibold text-gray-600">{tipo}</h2>
            <p className="text-2xl font-bold">
              {kpis.filter(kpi => kpi.tipoSimulacao === tipo).length}
            </p>
          </div>
        ))}
      </div>

      {/* Filtros */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-4 hide-on-print">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Filtros</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">

          {/* Filtro para Agente (Username) com autocomplete */}
          <Select
            value={selectedUserId}
            onChange={setSelectedUserId}
            placeholder="Selecione um Agente"
            className="w-full"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {filteredUsers.map(user => (
              <Option key={user.id} value={user.id}>
                {user.username}
              </Option>
            ))}
          </Select>

          {/* Filtro de Data */}
          <RangePicker
            onChange={handleDateChange}
            allowEmpty
            className="w-full"
          />
        </div>

        <div className="flex items-center justify-start space-x-4">
          {/* Botão para exportar para Excel */}
          <Button onClick={handleExportExcel} type="primary">
            Exportar para Excel
          </Button>

          {/* Botão para imprimir visualização */}
          <Button onClick={handlePrint} type="primary" className="bg-green-700">
            Imprimir Visualização
          </Button>

          {/* Botão para resetar os filtros */}
          <Button onClick={resetFilters} type="primary" danger>
            Limpar Filtros
          </Button>
        </div>
      </div>

      {/* Exibição dos Filtros Ativos */}
      {isFilterActive() && (
        <div className="bg-white p-4 rounded-lg shadow-md mb-2">
          <h2 className="text-xl font-bold text-gray-800 mb-2">Filtros Ativos</h2>
          <div className="flex flex-wrap">
            {selectedTipo && (
              <span className="bg-blue-100 text-blue-800 rounded-full px-4 py-1 text-sm font-semibold mr-2 mb-2">
                Tipo: {users.find(user => user.tipo?.id.toString() === selectedTipo)?.tipo.nivel}
              </span>
            )}
            {selectedUserId && (
              <span className="bg-blue-100 text-blue-800 rounded-full px-4 py-1 text-sm font-semibold mr-2 mb-2">
                User: {users.find(user => user.id === Number(selectedUserId))?.username}
              </span>
            )}
            {startDate && endDate && (
              <span className="bg-blue-100 text-blue-800 rounded-full px-4 py-1 text-sm font-semibold mr-2 mb-2">
                Data: {startDate} - {endDate}
              </span>
            )}
          </div>
        </div>
      )}

      {/* Gráfico de Barras */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-8">
        <Bar data={chartData} options={chartOptions} height={400} />
      </div>

      {/* Secção de Rácio por Tipo de Resultado, com ordem fixa dos estados */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Rácio por Tipo de Resultado</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {tiposSimulacao.map((tipo, index) => {
            const totalPorTipo = kpis.filter(kpi => kpi.tipoSimulacao === tipo).length;
            const estadosPorTipo = kpis
              .filter(kpi => kpi.tipoSimulacao === tipo)
              .reduce((acc, kpi) => {
                acc[kpi.estado] = (acc[kpi.estado] || 0) + 1;
                return acc;
              }, {} as Record<string, number>);

            return (
              <div key={index} className="p-4 bg-gray-50 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-600">{tipo}</h3>
                {estadoOrdem.map((estado, i) => {
                  const count = estadosPorTipo[estado] || 0;
                  const percent = ((count / totalPorTipo) * 100).toFixed(2);
                  return (
                    <p key={i} className="text-sm text-gray-800">
                      {estado}: {percent}%
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KPIsPage;

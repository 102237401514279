import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, theme, Avatar, Row, Col } from "antd";
import AppHeader from "./Appheader";
import { getToken } from "../../api/Auth/helpers";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../api/Auth/helpers";
import { FiCircle, FiLogIn, FiLogOut, FiPieChart, FiUsers } from "react-icons/fi";
import { AiOutlineProfile } from "react-icons/ai";
import { TbReportAnalytics, TbReportSearch } from "react-icons/tb";
import { LiaCashRegisterSolid } from "react-icons/lia";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { Outlet } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { BellOutlined } from '@ant-design/icons';
import { Popover } from 'antd';


const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

interface SideMenuProps {
  children?: React.ReactNode;
}

const SideMenu: React.FC<SideMenuProps> = ({ children }: any) => {
  const navigate = useNavigate();

  const [activeKey, setActiveKey] = useState<string | "1">(
    sessionStorage.getItem("activeKey") ?? "1"
  );

  const { user, triggerUpdate } = useAuthContext();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuRef = useRef<MenuProps>(null);

  useEffect(() => {
    // Update the active link when the location changes
    // active key must be only the first part of the url
    // e.g. /leads/1 -> /leads
    const newActiveKey = location.pathname.split("/")[1];
    if (newActiveKey !== activeKey) {
      setActiveKey(`/${newActiveKey}`);
      sessionStorage.setItem("activeKey", activeKey);
    }

    // triggerupdate
    triggerUpdate();
  }, [location.pathname]);

  const getUsername = () => {
    if (user && user.username) {
      return user.username;
    }
    return "Guest";
  };
  const userMenu = getItem(
    <span>{getUsername()}</span>,
    "6",
    <UserOutlined />,
    [
      getToken()
        ? getItem(
          <Link to="/profile">Profile</Link>,
          "/profile",
          <AiOutlineProfile />
        )
        : null,
      user && user.tipo && parseInt(user.tipo.level) <= 1
        ? getItem(<Link to="/tipo">Tipo</Link>, "/tipo", <FiLogIn />)
        : null,
      getToken()
        ? getItem(
          <span onClick={handleLogout}>Logout</span>,
          "/logout",
          <FiLogOut />
        )
        : null,
      !getToken()
        ? getItem(
          <Link
            to="/login"
            onClick={window.location.reload}
            className={
              location.pathname.startsWith("/login") ? "active-link" : ""
            }
          >
            Login
          </Link>,
          "/login",
          <FiLogIn />
        )
        : null,
    ]
  );

  const items: MenuItem[] = [
    getToken()
      ? getItem(
        <Link to="/">Dashboard</Link>,
        "/",
        user ? <PieChartOutlined /> : <LiaCashRegisterSolid />
      )
      : null,

    getToken()
      ? getItem(
        <Link to="/leads">Leads</Link>,
        "/leads",
        <LiaCashRegisterSolid />
      )
      : null,
    getToken()
      ? getItem(
        <Link to="/relatorios">Relatórios</Link>,
        "/relatorios",
        <TbReportSearch />
      )
      : null,
    user && user.tipo && parseInt(user.tipo.level) <= 3
      ? getItem(
        <Link to="/kpis">Kpis</Link>, "/kpis", <TbReportAnalytics />
      )
      : null,

    getToken()
      ? getItem(<Link to="/agentes">Agentes</Link>, "/agentes", <FiUsers />)
      : null,


    userMenu,
  ].filter(Boolean) as MenuItem[];

  function handleLogout() {
    removeToken();

    // remove every local and session storage
    localStorage.clear();
    sessionStorage.clear();

    // navigate to dashboard
    navigate("/");
    //  href to login
    window.location.reload()
  }




  return (
    <div className="teste">
      <AppHeader />

      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={200} // Set your preferred width
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            items={items}
            className="mt-2"
            defaultSelectedKeys={activeKey ? [activeKey] : []}
            selectedKeys={activeKey ? [activeKey] : []}
          />
        </Sider>

        <Layout>
          <Content
            style={{
              overflow: "auto",
              maxHeight: "calc(100vh - 64px)", // Adjust 64px based on your header height
            }}
          >
            <div
              style={{
                minHeight: 360,
                background: colorBgContainer,
                marginBottom: "7vh",
              }}
            >
              {/* outlet or children */}
              {children}
              <Outlet />
            </div>
          </Content>
          {/* <Footer
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              height: "7vh",
              backgroundColor: "rgb(241, 242, 244)",
              zIndex: 1,
              textAlign: "center",
            }}
          >
            Real Vida Seguros 2023
          </Footer> */}
        </Layout>
      </Layout>
    </div>
  );
};

export default SideMenu;

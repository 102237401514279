import React, { ReactNode, useEffect, useState } from "react";
import { getResultById2 } from "../../../../api/getSimInfo";
import { useNavigate, useParams } from "react-router-dom";
// import relatorio and createrelatorio
import LeadReport from "../../Relatorio";
import { CreateRelatorio, createObservacao } from "../../../../api/Backend";
import { Spin, message } from "antd";
import { useAuthContext } from "../../../../context/AuthContext";
import { RiArrowGoBackLine, RiFileTextLine } from "react-icons/ri";
import SimuladoModal from "../../sales_sim/Modal/NewSimModal";
import { FaPlus } from "react-icons/fa";

const ResultadoSeguroHabit = () => {
  const { id } = useParams<{ id: string }>();
  const [dados, setDados] = useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const { user } = useAuthContext();
  const [newSimData, setNewSimData] = useState({
    nome: "",
    email: "",
    phone: "",
    birthday: "",
    uuid: "",
    id: "",
    birthday2: "",
  });
  const [isNewSimModalOpen, setIsNewSimModalOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    user_id: "",
    referencia: "",
    username: "",
    created_at: "",
    id: "",
    saleSim: undefined,
  });
  const formatCurrency = (value: string | undefined) => {
    const numericValue = parseFloat(value as any);

    // If the value is 0, return "0,00€"
    if (numericValue === 0) {
      return "0,00€";
    }

    // If the value is not a valid number, return an empty string
    if (isNaN(numericValue)) {
      return "-";
    }

    return `${numericValue
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`;
  };


  useEffect(() => {
    const fetchDadosSeguro = async () => {
      const dados2 = await getResultById2(id);
      // dados2.resultado_simulacao = dados2.resultado_simulacao.result caso exista o result se nao volta ao normal
      if (dados2.resultado_simulacao.result) {
        dados2.resultado_simulacao = dados2.resultado_simulacao.result;
      }
      setDados(dados2);
      setLoading(false);
    };
    fetchDadosSeguro();
  }, [id]);

  const closeReportModal = () => {
    setIsReportModalOpen(false)
  };

  const handleCloseModal = () => {
    setIsNewSimModalOpen(false);
  };
  const openReportModal = () => {
    // change created at to readable string
    const date = new Date(dados?.createdAt);
    const dateStr = date.toLocaleDateString("pt-PT");

    // set aditionalData
    setAdditionalData({
      user_id: user?.id.toString() || "",
      referencia: dados?.uuid,
      username: user?.username || "",
      created_at: dateStr,
      id: dados?.idSimuladorVida,
      saleSim: dados?.saleSim,
    });

    setIsReportModalOpen(true);
  };

  const navigate = useNavigate();
  const handleFormSubmit = (formData: any, observacao?: any) => {
    // log the form mdata
    setLoading(true);

    CreateRelatorio(formData);

    // if observacao is set then createObservacao on backend
    if (observacao) {
      // create observacao
      createObservacao(observacao);
    }

    closeReportModal();
    message.success("Relatório enviado com sucesso, a retornar ás leads");
    // timout and return
    setTimeout(() => {
      navigate("/leads");
      window.location.reload();
    }, 1500);
  };

  window.onfocus = () => {
    // check if any modal is open if not reload
    if (!isReportModalOpen) window.location.reload();
  };

  const openNewSimModal = () => {
    setNewSimData({
      nome: dados?.nome,
      email: dados?.email,
      phone: dados?.phone,
      birthday: dados?.dados_simulacao.data_nasc_1,
      uuid: dados?.uuid,
      id: dados?.idSimuladorVida || "",
      // if data_nasc_2 is set then set birthday2
      birthday2: dados?.dados_simulacao.data_nasc_2 || "",
    });
    setIsNewSimModalOpen(true);
  };
  // loading
  if (!dados) {
    return (
      <div className="bg-gray-400 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Detalhes do Seguro</h1>
      <div className="flex justify-end">
        {/* Botão para nova simulação */}
        {/* <button
          className="bg-blue-200 hover:bg-blue-200 text-white px-4 py-2 rounded mr-2"
          onClick={openNewSimModal}
        >
          <FaPlus className="inline-block mr-2" />
          Gerar Nova Simulação
        </button> */}
        <button
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          onClick={openReportModal}
        >
          <RiFileTextLine className="inline-block mr-2" />
          Gerar Relatório
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
          onClick={() => navigate("/leads")}
        >
          <RiArrowGoBackLine className="inline-block mr-2" />
          Voltar
        </button>
      </div>
      {dados && (
        <>
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">Informações do Seguro</h2>
            <table className="w-full text-sm text-left text-gray-400">
              <tbody>
                <tr className="border-b bg-gray-800 border-gray-700">
                  {" "}
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                  >
                    Nome
                  </th>
                  <td
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                  >
                    {dados.dados_simulacao.nome}
                  </td>
                </tr>
                <tr className="border-b bg-gray-800 border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                  >
                    Email
                  </th>
                  <td
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                  >
                    {dados.dados_simulacao.email}
                  </td>
                </tr> <tr className="border-b bg-gray-800 border-gray-700
                  cursor-pointer
                 "
                  onClick={
                    // tel to
                    () => window.open(`tel:00351${dados.dados_simulacao.phone}`)
                  }
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                  >
                    Contacto
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    +351{" "}
                    <a href={`tel:00351${dados.dados_simulacao.phone}`}>
                      {dados.dados_simulacao.phone}
                    </a>
                  </td>
                </tr>
                <tr className="border-b bg-gray-900 border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                  >
                    Número de titulares
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.n_titulares}
                  </td>
                </tr>
                {dados.dados_simulacao.data_nascimento ? (
                  <tr className="border-b bg-gray-900 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Data de nascimento do titular
                    </th>
                    <td className="px-6 py-4">
                      {dados?.dados_simulacao?.data_nascimento}
                    </td>
                  </tr>
                ) : null}
                {/* data nascimento 2 */}
                {dados.dados_simulacao.data_nascimento_2 ? (
                  <tr className="border-b bg-gray-900 border-gray-700">
                    <th
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Data de nascimento do titular 2
                      </th>
                      <td className="px-6 py-4">
                        {dados?.dados_simulacao?.data_nascimento_2}
                      </td>
                    </tr>
                  ) : null}
              </tbody>
            </table>
          </div>
          {/* Tabela Dados da Simulação Original */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-8">
            <div className="container mx-auto p-8">
              <h2 className="text-2xl font-bold mb-4">
                Dados da Simulação Original
              </h2>
              <table className="w-full text-sm text-left text-gray-400">
                <tbody>
                  <tr className="border-b bg-gray-800 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Número de titulares
                    </th>
                    <td className="px-6 py-4">
                      {dados.resultado_simulacao.original.n_titulares}
                    </td>
                  </tr>
                  <tr className=" border-b bg-gray-900 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Capital em dívida
                    </th>
                    <td className="px-6 py-4">
                      {dados.resultado_simulacao.original.c_divida}€
                    </td>
                  </tr>
                  <tr className="border-b bg-gray-800 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Cobertura não incluida
                    </th>
                    <td className="px-6 py-4">
                      {dados.resultado_simulacao.original.cobertura}
                    </td>
                  </tr>
                  <tr className="border-b bg-gray-900 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Valor fraccionado
                    </th>
                    <td className="px-6 py-4">
                      {formatCurrency(
                        dados.resultado_simulacao.original.v_fraccionado
                      )}
                    </td>
                  </tr>
                  <tr className="border-b bg-gray-800 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Fraccionamento
                    </th>
                    <td className="px-6 py-4">
                      {dados.resultado_simulacao.original.fraccionamento}
                    </td>
                  </tr>
                  <tr className=" border-b bg-gray-900 border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap text-white"
                    >
                      Valor Anual
                    </th>
                    <td className="px-6 py-4">
                      {formatCurrency(dados.resultado_simulacao.original.v_anual)}
                    </td>
                  </tr>
                  {/* <tr className="bg-gray-400 border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Poupança anual
                    </th>
                    <td className="px-6 py-4">
                      {formatCurrency(dados.resultado_simulacao.original.p_anual)}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>

            {/* Tabela Dados da Simulação Sugerido */}
            <div className="container mx-auto p-8">
              <div>
                <h2 className="text-2xl font-bold mb-4">Dados da Simulação Sugerido</h2>
                <table className="w-full text-sm text-left text-gray-400">
                  <tbody>
                    <tr className="border-b bg-gray-800 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium whitespace-nowrap text-white"
                      >
                        Número de titulares
                      </th>
                      <td className="px-6 py-4">
                        {dados.resultado_simulacao.original.n_titulares}
                      </td>
                    </tr>
                    <tr className="border-b bg-gray-900 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium whitespace-nowrap text-white"
                      >
                        Capital em dívida
                      </th>
                      <td className="px-6 py-4">
                        {dados.resultado_simulacao.original.c_divida}€
                      </td>
                    </tr>
                    {dados.resultado_simulacao.sugerido.cobertura && (
                      <tr className="border-b bg-gray-800 border-gray-700">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                        >
                          Cobertura incluida
                        </th>
                        <td className="px-6 py-4">
                          {dados.resultado_simulacao.sugerido.cobertura}
                        </td>
                      </tr>
                    )}
                    <tr className=" border-b bg-gray-900 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                      >
                        Valor fraccionado
                      </th>
                      <td className="px-6 py-4">
                        {formatCurrency(
                          dados.resultado_simulacao.sugerido.v_fraccionado
                        )}
                      </td>
                    </tr>
                    <tr className="border-b bg-gray-800 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium whitespace-nowrap text-white"
                      >
                        Fraccionamento
                      </th>
                      <td className="px-6 py-4">
                        {dados.resultado_simulacao.sugerido.fraccionamento}
                      </td>
                    </tr>
                    <tr className=" border-b bg-gray-900 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                      >
                        Valor Anual
                      </th>
                      <td className="px-6 py-4">
                        {formatCurrency(dados.resultado_simulacao.sugerido.v_anual)}
                      </td>
                    </tr>
                    <tr className="border-b bg-gray-900 border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                      >
                        Poupança anual
                      </th>
                      <td className="px-6 py-4">
                        {formatCurrency(dados.resultado_simulacao.sugerido.p_anual)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {dados.resultado_simulacao.observacoes && (
                <div>
                  <h2 className="text-2xl font-bold mb-4">Observações</h2>
                  <table className="w-full text-sm text-left text-gray-400">
                    <tbody>
                      {dados.resultado_simulacao.observacoes.map(
                        (observacao: any, index: number) => (
                          <tr
                            key={index}
                            className="border-b  bg-gray-800 border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium  whitespace-nowrap text-white"
                            >
                              {observacao.user}
                            </th>
                            <td className="px-6 py-4">{observacao.texto}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            </div>

          {/* Relatório */}
          <LeadReport
            isOpen={isReportModalOpen}
            onRequestClose={closeReportModal}
            onSubmit={handleFormSubmit}
            additionalData={additionalData}
          />
          <SimuladoModal
            isOpen={isNewSimModalOpen}
            onRequestClose={handleCloseModal}
            dataRecieved={newSimData}
          />
        </>
      )}
    </div>
  );
};

export default ResultadoSeguroHabit;

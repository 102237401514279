import React, { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { useNavigate } from "react-router-dom";
import { Card, Collapse, Typography,List } from "antd";

const { Panel } = Collapse;
const { Text, Title } = Typography;
// Função personalizada para renderizar o rótulo ativo no gráfico
const renderCustomActiveShape = (props: any): React.ReactElement => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    fill,
    payload,
    percent,
    value,
  } = props;

  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${payload.name}: ${value}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
type SalesPieChartProps = {
  data: { name: string; value: number; color: string }[];
  tipoCount: Record<string, number>;
  estadoCount: Record<string, number>;
  TotalDeTodasLeads: number;
  tipoEstadoCount: Record<string, Record<string, number>>;
};

const SalesPieChart: React.FC<SalesPieChartProps> = ({ data, tipoCount, estadoCount, TotalDeTodasLeads, tipoEstadoCount }) => {

  const navigate = useNavigate();

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const handleToggle = (key: string) => {
    setActiveKeys((prev) => (prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key]));
  };
  // Renomeia "Entregue" para "Por Tratar"
  const processedData = data.map((entry) => {
    if (entry.name === "Entregue") {
      return { ...entry, name: "Por Tratar" };
    }
    return entry;
  });

  // Filtra dados para o primeiro gráfico (leads abertas)
  const openLeadsData = processedData.filter((entry) =>
    ["Aberta", "Por Tratar", "Reagendada"].includes(entry.name)
  );

  // Filtra dados para o segundo gráfico (leads fechadas)
  const closedLeadsData = processedData.filter((entry) =>
    ["Fechada", "Não atendeu", "Falsa", "Repetida", "Cancelada"].includes(entry.name)
  );

  let startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
  // simplifica para 01 de Outubro de 2020 ex
  startOfMonth = startOfMonth.substring(0, 10);
  // simplifica para 31 de Outubro de
  const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59).toISOString();
  startOfMonth = startOfMonth.replace(/-/g, '/');
  endOfMonth.replace(/-/g, '/');




  return (
    <>
      {data.length > 0 ? (
        <Card title="Estado das Leads durante o mês atual" className="mb-6">
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              {openLeadsData.length > 0 && (
                <Pie
                  data={openLeadsData}
                  cx={closedLeadsData.length > 0 ? "25%" : "50%"}
                  cy="50%"
                  outerRadius={120}
                  dataKey="value"
                  onClick={() => navigate("/leads/abertas")}
                  label={renderCustomActiveShape}
                >
                  {openLeadsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                  <Label
                    value="Leads em Aberto"
                    position="center"
                    className="text-2xl font-extrabold"
                    fill="#fff"
                    style={{ textShadow: "0 0 5px #000" }}
                  />
                </Pie>
              )}

              {closedLeadsData.length > 0 && (
                <Pie
                  data={closedLeadsData}
                  cx={openLeadsData.length > 0 ? "75%" : "50%"}
                  cy="50%"
                  outerRadius={120}
                  dataKey="value"
                  label={renderCustomActiveShape}
                  onClick={() => navigate("/leads")}
                >
                  {closedLeadsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                  <Label
                    value="Leads Fechadas"
                    position="center"
                    className="text-2xl font-extrabold"
                    fill="#fff"
                    style={{ textShadow: "0 0 5px #000" }}
                  />
                </Pie>
              )}
            </PieChart>
          </ResponsiveContainer>

          <div className="flex flex-col items-center mt-4">
            <Text className="text-2xl font-extrabold">Total de leads: {TotalDeTodasLeads}</Text>
          </div>

          {/* Lista com detalhes das simulações */}
          <Collapse accordion>
            <Panel header="Total de Simulações por produto" key="1">
              <List
                bordered
                dataSource={Object.entries(tipoCount)}
                renderItem={([tipo, count]) => (
                  <List.Item>
                    {tipo}: {count} Lead(s)
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header="Total de Simulações por Estado" key="2">
              <List
                bordered
                dataSource={Object.entries(estadoCount)}
                renderItem={([estado, count]) => (
                  <List.Item>
                    {estado}: {count} Lead(s)
                  </List.Item>
                  // total 
                )}
              />
            </Panel>
            <Panel header="Detalhamento por produto e Estado" key="3">
              <Collapse bordered={false}>
              
                {Object.entries(tipoEstadoCount).map(([tipo, estados]) => ({
                  key: tipo,
                  label: <strong>{tipo}</strong>,
                  children: (
                    <List
                      bordered
                      dataSource={Object.entries(estados)}
                      renderItem={([estado, count]) => (
                        <List.Item>
                          {estado} : {count} Lead(s)
                        </List.Item>
                      )}
                    />
                  )
                })).map((panel) => (
                  <Panel key={panel.key} header={panel.label}>
                    {panel.children}
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          </Collapse>
        </Card>
      ) : (
        <Card title="Bem vindo! Leads durante o mês atual" className="mb-6">
          <div className="flex flex-col items-center justify-center">
            <Text className="text-2xl font-extrabold">Sem leads!</Text>
            <Text className="text-2xl font-extrabold">Aguarde até receber uma lead</Text>
          </div>
        </Card>
      )}
    </>
  );

};

export default SalesPieChart;

import React, { useEffect, useState } from "react";
import { Table, Spin, Button, message, Checkbox, Input, Select } from "antd";
import { useAuthContext } from "../context/AuthContext";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import EditLeadModal from "./EditLeadModal";
import { updateLead } from "../api/Backend";
import axios from "axios";
import { API, BEARER } from "../api/Auth/constant";
import { getToken } from "../api/Auth/helpers";
import qs from 'qs';
import { RiCloseLine, RiRefreshFill } from "react-icons/ri";
import './styles.css';
const { Search } = Input;
interface SimuladorData {
  attributes: {
    nome: string;
    email: string;
    phone: string;
    data_entrega: string;
    resultado_simulacao: any;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    user: any;
    estado: any;
    tipo_simulacao: any;
    dados_simulacao: any;
    reagendado: boolean;
    data_reagendamento: any;
    observacoes: any[];
  },
  id: number;
}


const LeadsPage2: React.FC = () => {
  const navigate = useNavigate();
  const { filters } = useParams();
  const authToken = getToken();
  const { user, users } = useAuthContext();

  const [simuladorData, setSimuladorData] = useState<SimuladorData[]>([]);
  const [selectedLead, setSelectedLead] = useState<SimuladorData | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [stateFilter, setStateFilter] = useState<string[]>(["Entregue", "Aberta", "Reagendada"]);
  const [agentFilter, setAgentFilter] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [TotalSimulacoes, setTotalSimulacoes] = useState<number>(0);
  const [simulatorFilter, setSimulatorFilter] = useState<string | null>(null);



  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleAgentFilter = (value: string) => {
    setAgentFilter(value);
  };

  const handleStateFilter = (value: string[]) => {
    setStateFilter(value);
  };

  const filteredData = simuladorData.filter((lead) => {
    const { nome, email, phone, estado, tipo_simulacao } = lead.attributes;

    return (
      // Filtros existentes
      (nome && nome.toLowerCase().includes(searchText.toLowerCase())) ||
      (email && email.toLowerCase().includes(searchText.toLowerCase())) ||
      (phone && phone.toLowerCase().includes(searchText.toLowerCase())) ||
      (stateFilter.includes(estado)) ||
      (agentFilter && lead.attributes.user.data.attributes.username === agentFilter) ||

      // Filtro por simulador
      (!simulatorFilter || tipo_simulacao.data.attributes.simulador === simulatorFilter)
    );
  });


  const startOfCurrentWeek = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Ajuste para Domingo ser considerado último dia da semana
    return new Date(now.setDate(diff));
  };

  const fetchSimulacoes = async () => {
    let params1: Record<string, any> = {};
    try {
	  if (!user?.tipo?.level) {
      	return;
      }
      setLoading(true);
      const offset = (currentPage - 1) * pageSize;
      params1 = {
        "pagination[start]": offset,
        "pagination[limit]": pageSize,
        "sort[0]": "id:desc",
        "filters[estado][$eq][0]": stateFilter,
        "populate[]": [
          "user",
          "user.equipa",
          "user.equipa.zona",
          "tipo_simulacao",
          "resultado_simulacao",
          "observacoes",
        ],
      };


      if (simulatorFilter) {
        params1["filters[$and][0][tipo_simulacao][simulador][$eq]"] = simulatorFilter;
      }

      if (searchText) {
        params1["_q"] = searchText;
      }
      /* if agent filter then filter by  user id*/
      if (agentFilter) {
        const agent = users.find((u) => u.username === agentFilter);
        if (agent) {
          params1["filters[user][id][$eq]"] = agent.id;
        }
      }


      // Verificação do nível do usuário
      if (user?.tipo?.level === 3) {
        params1["filters[user][equipa][zona][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level === 4) {
        params1["filters[user][equipa][users_permissions_user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level >= 5) {
        params1["filters[user][id][$eq]"] = user?.id;
      } else if (user?.tipo?.level <= 2) {
        // Não é necessário adicionar filtros específicos, pois esses usuários têm acesso sem restrições
      }


      const response = await axios.get(`${API}/simulacoes`, {
        params: params1,
        headers: { Authorization: `${BEARER} ${authToken}` },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });


      if (Array.isArray(response.data.data)) {
        setSimuladorData(response.data.data);
        setLoading(false);
        setTotalSimulacoes(response.data.meta.pagination.total);


      } else {
        console.error("Dados retornados não são um array:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar simulações:", error);
      message.error("Erro ao buscar simulações.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchSimulacoes(); // Chama a função quando o componente é montado
    }
  }, [user]); // Execute apenas na montagem inicial


  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }

    fetchSimulacoes()
  };

  useEffect(() => {
    fetchSimulacoes();
  }, [currentPage, pageSize, stateFilter, searchText, agentFilter, simulatorFilter]);

  useEffect(() => {
    if (filters) {
      switch (filters) {
        case "abertas":
          setStateFilter(["Aberta"]);
          break;
        case "entregue":
          setStateFilter(["Entregue"]);
          break;
        case "reagendada":
          setStateFilter(["Reagendada"]);
          break;
        default:
          setStateFilter(["Entregue", "Aberta"]);
          break;
      }
    }
  }, [filters]);

  const [canEdit, setCanEdit] = useState(false);
  useEffect(() => {
    if (user) {
      const userLevel = user.tipo.level;
      if (userLevel <= 4) {
        setCanEdit(true);
      }
    }
  }, [user]);

  const renderPhone = (text: string) => {
    const spaceIndex = text.indexOf(" ");

    if (spaceIndex !== -1) {
      const visiblePart = text.slice(0, spaceIndex + 1);
      const hiddenPart = text.slice(spaceIndex + 1, -4).replace(/./g, "*");
      const lastFourDigits = text.slice(-4);
      return <span>{visiblePart + hiddenPart + lastFourDigits}</span>;
    } else {
      const lastFourDigits = text.slice(-4);
      const hiddenPart = text.slice(0, -4).replace(/./g, "*");
      return <span>{hiddenPart + lastFourDigits}</span>;
    }
  };

  const handleEditClick = (record: SimuladorData) => {
    setSelectedLead(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedLead(null);
  };

  const handleModalSave = async (selectedOption: any) => {
    setLoading(true);
    await updateLead(selectedLead?.id, selectedOption);
    message.success("Alteração realizada com sucesso");

    setTimeout(() => {
      setIsModalVisible(false);
      setSelectedLead(null);
      setLoading(false);
    }, 1000);
  };



  const columns = [
    {
      title: "Data da Simulação",
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",

      render: (text: string) => {
        if (text) {
          return (
            <span>
              {new Date(text).toLocaleString("pt-PT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          );
        }
        return <span>Ainda não entregue</span>;
      },
    },
    {
      title: "Nome",
      dataIndex: ["attributes", "nome"],
      key: "name",
    },
    {
      title: "Telefone",
      dataIndex: ["attributes", "phone"],
      key: "phone",
      render: (text: string) => renderPhone(text),
    },
    {
      title: "Alocada a",
      key: "username",
      // render href to agente user
      render: (text: string, record: any) => (
        <span>
          {record.attributes.user && record.attributes.user.data ? (
            <span
            >
              {record.attributes.user.data.attributes.username}
            </span>
          ) : (
            <Spin />
          )}
        </span>
      ),
    },
    // data de entrega
    {
      title: "Data de Entrega",
      dataIndex: ["attributes", "data_entrega"],
      key: "data_entrega",
      // render data de entrega por baixo do texto a dizer data de entrega deve apenas dizer dd-mm hh:mm
      render: (text: string) => {
        // if text return if not return ainda nao entregue
        if (text) {
          return (
            <span>
              {new Date(text).toLocaleString("pt-PT", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </span>
          );
        }
        return <span>Ainda não entregue</span>;
      },
    },
    {
      title: "ID da lead",
      dataIndex: ["attributes", "uuid"],
      key: "uuid",
    },
    // estado
    {
      title: "Estado",
      dataIndex: ["attributes", "estado"],
      key: "estado",
      render: (text: string, record: any) => (
        <div className="flex flex-col items-center gap-2">
          <span
            className="text-xs text-center"
            style={{
              backgroundColor: getEstadoColor(record.attributes.estado),
              borderRadius: "5px",
              padding: "5px",
              color: "black",
            }}
          >
            {text}
          </span>
          {record.attributes.estado === "Reagendada" &&
            record.attributes.data_reagendamento && (
              <>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </>
            )}
        </div>
      ),
    },
    {
      title: "Categoria",
      dataIndex: [
        "attributes",
        "tipo_simulacao",
        "data",
        "attributes",
        "categoria",
      ],
      key: "categoria",
    },
    {
      title: "Simulador",
      dataIndex: [
        "attributes",
        "tipo_simulacao",
        "data",
        "attributes",
        "simulador",
      ],
      key: "simulador",
    },
    {
      title: "Ações",
      key: "Acoes",
      render: (text: string, record: any) => (
        <span className="flex">
          <Button
            icon={<AiOutlineEye />}
            className="mr-2 text-white bg-green-500 hover:bg-green-300"
            onClick={() => {
              const uuid = record.attributes.uuid;
              const slug =
                record.attributes.tipo_simulacao.data.attributes.slug;

              navigate(`/${slug}/${uuid}`);
            }}
          />

          {canEdit && (
            <Button
              icon={<AiOutlineEdit />}
              className="text-white bg-blue-500 hover:bg-blue-300"
              onClick={() => handleEditClick(record)}
            />
          )}
        </span>
      ),
    },
  ];

  const getEstadoColor = (estado: string): string => {
    switch (estado) {
      case "Fechada":
        return "#ff4d4f";
      case "Aberta":
        return "#ffa940";
      case "Entregue":
        return "#73d13d";
      case "Reagendada":
        return "#9254de";
      case "Cancelada":
        return "#595959";
      case "Falsa":
        return "#ffec3d";
      case "Repetida":
        return "#40a9ff";
      case "Outro":
        return "#ff7a45";
      case "Contacto Errado":
        return "#2596be"; // Cor a definir para este estado
      case "Não atendeu":
        return "#e28743"; // Cor a definir para este estado
      case "Não vai avançar":
        return "#abdbe3"; // Cor a definir para este estado

      default:
        return "#000000";
    }
  };

  const getFilteredUsers = () => {
    if (!user || !user.tipo) {
      // Retorna um array vazio se o usuário não estiver definido ou não tiver tipo
      return [];
    }

    const userLevel = user.tipo.level;

    switch (userLevel) {
      case 5:
        // Nível 5 (agentes) não deve ver nenhum usuário
        return [];
      case 0:
      case 1:
        // Nível 0 e 1 (administradores) vêem todos os usuários
        return users;
      case 2:
        // Nível 2 (diretores) vêem todos os usuários, exceto administradores (nível 0 e 1)
        return users.filter((u) => u.tipo.level > 1);
      case 3:
        // Nível 3 (coordenadores) devem ver os usuários que pertencem à sua zona
        const filteredUsers = users.filter(
          (u) => u.equipa?.zona?.id === user.equipa?.zona?.id
        );

        // Se não houver usuários na zona, retorne um array com a mensagem "coordenador sem zona"
        return filteredUsers.length > 0
          ? filteredUsers
          : [{ id: 'no-zone', username: 'Coordenador sem zona' }];
      case 4:
        // Nível 4 (gestores comerciais) vêem todos os agentes da sua equipa 
        return users.filter((u) => u.equipa?.id === user.equipa?.id);
      default:
        // Para todos os outros casos, não deve haver usuários filtrados
        return [];
    }
  };


  return (
    <div className="flex flex-col items-center justify-center px-4 py-4 bg-gray-50 sm:px-6 lg:px-8 overflow-y-hidden">
      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-white opacity-75">
          <Spin className="text-blue-500" />
        </div>
      ) : (
        <div className="w-full max-w-8xl p-6 bg-white rounded-lg shadow-xl sm:p-4 lg:p-6 ">
          <div className="p-4 overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="flex max-w-full mb-4 space-x-4 ">
              <Search
                placeholder="Pesquisar"
                onSearch={handleSearch}
                style={{ width: 600 }}
              />
              {user?.tipo?.level < 5 && (
                <Select
                  showSearch
                  placeholder="Filtrar por Agente"
                  onChange={handleAgentFilter}
                  value={agentFilter ? agentFilter : ""}
                  style={{ width: 200 }}
                  defaultValue={"Todos os agentes"}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    const optionChildren = option?.children;
                    return optionChildren
                      ? optionChildren
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false;
                  }}
                >
                  <Select.Option value="">Todos os agentes</Select.Option>
                  {getFilteredUsers().map((user) => (
                    <Select.Option key={user.id} value={user?.username}>
                      {user?.username}
                    </Select.Option>
                  ))}
                </Select>
              )}
              <Select
                mode="multiple"
                placeholder="Filtrar por Estado"
                onChange={handleStateFilter}
                style={{ width: 200 }}
                value={stateFilter.length > 0 ? stateFilter : []}
                allowClear
              >
                <Select.Option value="Entregue">Entregue</Select.Option>
                <Select.Option value="Reagendada">Reagendado</Select.Option>
                <Select.Option value="Aberta">Aberta</Select.Option>
                <Select.Option value="Fechada">Fechada</Select.Option>
                <Select.Option value="Cancelada">Cancelada</Select.Option>
                <Select.Option value="Falsa">Falsa</Select.Option>
                <Select.Option value="Repetida">Repetida</Select.Option>
                <Select.Option value="Não atendeu">Não atendeu</Select.Option>
                <Select.Option value="Não vai avançar">Não vai avançar</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select>
              <Select
                placeholder="Filtrar por Simulador"
                onChange={setSimulatorFilter}
                style={{ width: 200 }}
                value={simulatorFilter || ""}
              >
                <Select.Option value="">Todos os Simuladores</Select.Option>
                <Select.Option value="Vida Habitação">Vida Habitação</Select.Option>
                <Select.Option value="Saude">Saude</Select.Option>
                <Select.Option value="Vida Futura">Vida Futura</Select.Option>
                <Select.Option value="Vida Pleno">Vida Pleno</Select.Option>
              </Select>
              <Button
                type="primary"
                className="bg-blue-500 hover:bg-blue-600/20"
                onClick={() => fetchSimulacoes()}
              >
                <RiRefreshFill />
              </Button>
            </div>
            <Table
              dataSource={filteredData}
              loading={loading}
              rowKey={(record) => record.attributes.uuid}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: TotalSimulacoes,
                onChange: handlePageChange,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} leads`,
              }}
              scroll={{ y: 'calc(100vh - 400px)' }} // Permite o scroll vertical
              onRow={(record) => ({
                onClick: (event) => {
                  // Verificar se o elemento clicado é um botão ou um elemento filho de um botão
                  if ((event.target as Element).closest("button")) {
                    // Se for um botão, evita a execução da navegação
                    return;
                  }

                  // Caso contrário, executa a navegação para a lead específica
                  const uuid = record.attributes.uuid;
                  const slug = record.attributes.tipo_simulacao.data.attributes.slug;
                  navigate(`/${slug}/${uuid}`);
                },
              })}
            />
          </div>

          <EditLeadModal
            record={selectedLead}
            visible={isModalVisible}
            onCancel={handleModalCancel}
            onSave={handleModalSave}
          />
        </div>
      )}
    </div>
  );
};

export default LeadsPage2;
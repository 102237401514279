import React, { useState, useEffect } from 'react';
import { List, Button, notification as antdNotification, Modal } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { getToken } from '../../api/Auth/helpers';
import { useAuthContext } from '../../context/AuthContext';

// Definindo a interface de Notificação
interface Notification {
    id: number | string;
    read: boolean;
    notification: {
        message: string;
        time: string;
        level: 'Low' | 'Medium' | 'High';
        link?: string;
    };
}

// Função auxiliar para obter a cor baseada na prioridade
function getPriorityColor(level: string) {
    switch (level) {
        case 'High': return 'red';
        case 'Medium': return 'orange';
        case 'Low': return 'green';
        default: return 'grey';
    }
}

const NotificationComponent = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { user } = useAuthContext(); // Supondo que 'user' contém o ID do usuário autenticado
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false); // Flag para saber se deve apagar

    const fetchNotifications = async () => {
        const token = getToken();
        try {
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users?filters[user][id][$eq]=${user?.id}&filters[deleted_at][$null]=true&populate=*&sort=id:DESC`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                const formattedNotifications = result.data.map((item: any) => ({
                    id: item.id,
                    read: item.attributes.read,
                    notification: item.attributes.notification.data.attributes // Presumindo que essa é a estrutura de uma notificação
                }));
                setNotifications(formattedNotifications);
            } else {
                throw new Error('Failed to fetch notifications');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Could not fetch notifications.',
            });
        }
    };

    const markAsRead = async (notificationUserId: number | string) => {
        const token = getToken();
        try {
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notificationUserId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { read: true } }), // Adaptação para Strapi v4
            });
            if (response.ok) {
                fetchNotifications(); // Atualiza as notificações após marcar como lida
            } else {
                throw new Error('Falha ao marcar a notificação como lida');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Erro a marcar a notificação como lida.',
            });
        }
    };

    const markAllAsRead = async () => {
        const token = getToken();
        try {
            // Itera sobre todas as notificações e as marca como lidas
            await Promise.all(notifications.map(async (notification) => {
                if (!notification.read) {
                    const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notification.id}`, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ data: { read: true } }), // Adaptação para Strapi v4
                    });
                    if (!response.ok) {
                        throw new Error('Failed to mark notification as read');
                    }
                }
            }));
            fetchNotifications(); // Atualiza a lista de notificações após marcar todas como lidas
            antdNotification.success({
                message: 'Success',
                description: 'Todas as notificações marcadas como lidas.',
            });
            if (shouldDelete) {
                await deleteAllNotifications(); // Chama a função para deletar se a flag estiver verdadeira
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Erro a marcar as notificações.',
            });
        }
    };

    const deleteAllNotifications = async () => {
        const token = getToken();
        try {
            await Promise.all(notifications.map(async (notification) => {
                const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notification.id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        data:
                        {
                            deleted_at: new Date().toISOString(),
                            read: true
                        }
                    }), // Define 'deleted_at' para o timestamp atual
                });
                if (!response.ok) {
                    throw new Error('Failed to delete notification');
                }
            }));
            setNotifications([]); // Limpa a lista de notificações após a deleção
            antdNotification.success({
                message: 'Success',
                description: 'Todas as notificações removidas com sucesso.',
            });
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Erro ao remover as notificações.',
            });
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [user?.id]); // Refetch when user changes

    const deleteNotification = async (notificationId: number | string) => {
        const token = getToken();
        try {
            // Atualiza o campo 'deleted_at' para o timestamp atual para realizar o soft delete
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notificationId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { deleted_at: new Date().toISOString() } }), // Define 'deleted_at' para o timestamp atual
            });
            if (response.ok) {
                // Remove a notificação da lista no estado após a remoção bem-sucedida
                setNotifications(currentNotifications =>
                    currentNotifications.filter(notification => notification.id !== notificationId)
                );
                antdNotification.success({
                    message: 'Success',
                    description: 'Notificação removida com sucesso.',
                });
            } else {
                throw new Error('Failed to delete notification');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Erro ao remover a notificação.',
            });
        }
    };

    const handleViewAll = () => {
        // Aqui você pode redirecionar o usuário para uma página de detalhes das notificações
        window.location.href = '/notifications'; // Ajuste a URL conforme necessário
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setShouldDelete(true); // Marca a flag para deletar
        markAllAsRead(); // Marca todas como lidas
        setIsModalVisible(false); // Fecha o modal
        // voltar a meter falso o set should
        setTimeout(() => {
            setShouldDelete(false);
        }
        , 1000);
    };

    // const handleCancel = () => {
    //     setShouldDelete(false); // Não deve deletar
    //     markAllAsRead(); // Marca todas como lidas
    //     setIsModalVisible(false); // Fecha o modal
        
    // };

    return (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Button type="primary" onClick={showModal} style={{ marginBottom: '10px' }}>
                Marcar Todas como Lidas
            </Button>
            <Modal
                title="Confirmação"
                open={isModalVisible}
                onOk={handleOk}
                // oncancel just close the modal
                onCancel={() => setIsModalVisible(false)}
                okText="Sim, marcar e apagar"
                cancelText="Cancelar"
            >
                <p>Você deseja marcar todas as notificações como lidas e também apagá-las?</p>
            </Modal>
            <List
                itemLayout="horizontal"
                dataSource={notifications}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                type="link"
                                onClick={() => markAsRead(item.id)}
                                icon={<CheckOutlined />}
                                disabled={item.read}
                            >
                            </Button>,
                            <Button
                                type="link"
                                onClick={() => deleteNotification(item.id)}
                                icon={<DeleteOutlined />}
                            >
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            title={<a href={`${process.env.REACT_APP_PUBLIC_URL}${item.notification.link || '#'}`} target="_blank" rel="noopener noreferrer">{item.notification.message}</a>}
                            description={
                                <>
                                    <div>Received at {new Date(item.notification.time).toLocaleString()}</div>
                                    <div style={{ color: getPriorityColor(item.notification.level) }}>Priority: {item.notification.level}</div>
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

export default NotificationComponent;

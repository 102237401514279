import React, { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, Form, message, Modal, Select } from "antd";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import type { RangePickerProps } from "antd/es/date-picker";
import { useAuthContext } from "../../context/AuthContext";

import locale from 'antd/locale/pt_PT';
import dayjs from 'dayjs';

import 'dayjs/locale/pt';

dayjs.locale('pt');
interface ReportModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (formData: FormData, observacao?: observacoes) => void; // Pass the form data to onSubmit
  additionalData: AdditionalData;
}

interface FormData {
  referencia: string;
  dataRececao: string;
  numeroAgente: string;
  nomeAgente: string;
  resultadoTratamento: string;
  reagendamentoDataHora: string;
  outros: string;
  numeroApolices: "";
  id: string;
  fechouSimulacoes: []; // Adicionado para armazenar simulações fechadas
}
interface SimulationResult {
  age: number;
  yearK: number;
  capital: number;
  papr: number;
  pacr: number;
  cmp: number;
  prto: number;
  prtoAgv: number;
  prto_Monthly: number;
  prtoAgv_Monthly: number;
  pac: number;
  papf: number;
  pmaf: number;
  rgpf: number;
  rgpf_Death: number;
}

interface SaleSimData {
  id: number;
  attributes: {
    nome: string;
    email: string;
    phone: string;
    resultado_simulacao: any;
    dados_simulacao: any;
    createdAt: string;
    updatedAt: string;
    tipo_simulacao: {
      data: {
        id: number;
        attributes: {
          simulador: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    };
  };
}

interface SaleSimInterface {
  referencia: string;
  user_id: string;
  username: string;
  created_at: string;
  id: number;
  saleSim?: {
    data: SaleSimData[];
  };
}

interface AdditionalData {
  referencia: string;
  user_id: string;
  username: string;
  created_at: string;
  id: string;
  saleSim?: {
    data: SaleSimData[];
  };
}

interface observacoes {
  user: number;
  texto: string;
  simulacao: number;
}

const LeadReport: React.FC<ReportModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  additionalData,
}) => {
  // Form errors
  const [formErrors, setFormErrors] = useState({
    reagendamentoDataHora: "",
    outros: "",
    numeroApolices: "",
  });

  const { triggerUpdate } = useAuthContext();
  const [formData, setFormData] = useState<FormData>({
    referencia: "",
    dataRececao: "",
    numeroAgente: "",
    nomeAgente: "",
    resultadoTratamento: "",
    reagendamentoDataHora: "",
    outros: "",
    numeroApolices: "",
    id: "", // ou qualquer valor padrão de string que faça sentido
    fechouSimulacoes: [], // Adicionado para armazenar simulações fechadas
  });
  const [formData2, setFormData2] = useState<any>({
    texto: "",
  });

  const addMinutes = (dateString: any, minutes: any) => {
    const dateObject = new Date(dateString);
    dateObject.setMinutes(dateObject.getMinutes() + minutes);
    return dateObject;
  };

  const [formattedStartDate, setFormattedStartDate] = useState<string>("");
  const [formattedStartMinutes, setFormattedStartMinutes] =
    useState<string>("");
  const [addtime, setAddTime] = useState<string>("");

  // make function to formate dates
  const formatMinutes = (date: string) => {
    const dateObject = new Date(date);
    const hours = dateObject.getHours();
    const minutes =
      dateObject.getMinutes() < 10
        ? "0" + dateObject.getMinutes()
        : dateObject.getMinutes();
    const formattedDate = `${hours}:${minutes}`;
    return formattedDate;
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today but should be able to select today
    const today = dayjs().endOf("day");

    return current && current < today.startOf("day");
  };

  const disabledDateTime = () => ({
    // disabledhours - only available must be between 7 and 22
    disabledHours: () => range(0, 7).concat(range(22, 24)),
  });

  useEffect(() => {
    if (formattedStartDate && formattedStartMinutes) {
      const addTime = addMinutes(
        formattedStartDate + " " + formattedStartMinutes,
        30
      );
      const formatedAddTime = formatMinutes(addTime.toString());

      setAddTime(formatedAddTime);
    }
  }, [formattedStartDate, formattedStartMinutes]);
  // set the additional data to the formData
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      referencia: additionalData.referencia,
      numeroAgente: additionalData.user_id,
      nomeAgente: additionalData.username,
      dataRececao: additionalData.created_at,
      id: additionalData.id,
    }));
    triggerUpdate();
  }, [additionalData]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange2 = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData2((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = () => {

    // check all required data, if all is set then continue
    if (
      formData.referencia &&
      formData.numeroAgente &&
      formData.nomeAgente &&
      formData.dataRececao &&
      formData.id &&
      formData2.texto
    ) {


      // Check if reagendamentoDataHora is required
      if (formData.resultadoTratamento === "Reagendou contacto") {
        if (
          !formData.reagendamentoDataHora ||
          formData.reagendamentoDataHora === ""
        ) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            reagendamentoDataHora: "Por favor selecione data e hora.",
          }));
          message.error("Por favor selecione data e hora.")
          return; // Prevent form submission
        }
      }
      // Check if outros is required
      if (formData.resultadoTratamento === "Outros") {
        if (!formData2.texto || formData2.texto === "") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            outros: "Insira uma observação válida.",
          }));
          message.error("Insira uma observação válida.")
          return; // Prevent form submission
        }
      }
      if (formData.resultadoTratamento === "Fechou apólice") {
        // Verificar se o campo numeroApolices foi preenchido
        if (!formData.numeroApolices || formData.numeroApolices === "") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            numeroApolices:
              "Por favor, introduza pelo menos um número da apólice.",
          }));
          return; // Evitar a submissão do formulário
        }
      }

      // Assuming selectedSimSales is an array, you can update it here
      const updatedFormData = {
        ...formData,
        SelectedSimSales: formData.fechouSimulacoes,
      };

      // set observacao data
      const updatedFormData2 = {
        // put user id as int
        user: parseInt(additionalData.user_id),
        texto: formData2.texto,
        simulacao: parseInt(additionalData.id),
      };

      // Submit the form data, if observacao then send if not dont send observacao
      onSubmit(updatedFormData, formData2.texto ? updatedFormData2 : undefined);

    } else {
      message.warning("Preencha todos os campos");
      return
    }
  };

  const [selectedSalesSim, setSelectedSalesSim] = useState<string>("");
  // log tipo_simulacao name of the first simulation

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorTextLightSolid: "#0000000",
          colorPrimaryBg: "#00b96b",
          colorBorder: "#e5e7eb",
        },
      }}
    >
      <Modal
        title="Relatório"
        open={isOpen} // Changed from "open" to "visible"
        onCancel={onRequestClose}
        onOk={handleFormSubmit}
        okButtonProps={{ className: "bg-blue-500 text-white" }}
        width={800}
        className="bg-transparent"
      >
        <h2 className="text-2xl font-bold mb-4">Formulário de Relatório</h2>
        <div className="mb-4">
          <label className="block mb-2">Referência:</label>
          <input
            type="text"
            name="referencia"
            value={formData.referencia}
            disabled
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Data de Receção:</label>
          <input
            type="text"
            name="dataRececao"
            value={formData.dataRececao}
            disabled
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Nº Agente:</label>
          <input
            type="text"
            name="numeroAgente"
            value={formData.numeroAgente}
            onChange={handleInputChange}
            disabled
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Nome do Agente:</label>
          <input
            type="text"
            name="nomeAgente"
            value={formData.nomeAgente}
            disabled
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Resultado do Tratamento:</label>
          <select
            name="resultadoTratamento"
            value={formData.resultadoTratamento}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Selecione...</option>
            <option value="Fechou apólice">Fechou apólice</option>
            <option value="Não atendeu">Não atendeu</option>
            <option value="Reagendou contacto">Reagendou contacto</option>
            <option value="Não vai avançar">Não vai avançar</option>
            <option value="Lead falsa">Lead falsa</option>
            <option value="Lead repetida">Lead repetida</option>
            <option value="Outros">Outros</option>
          </select>
        </div>
        {formData.resultadoTratamento === "Reagendou contacto" && (
          <div className="mb-4">
            <label className="block mb-2">Para quando? Data e Hora:</label>
            <DatePicker
              showNow={false}
              showTime
              name="reagendamentoDataHora"
              onChange={(value) => {
                setFormData((prevData) => ({
                  ...prevData,
                  reagendamentoDataHora: value?.toString() || "",
                }));
                setFormattedStartDate(value?.format("YYYY-MM-DD") || "");
                setFormattedStartMinutes(value?.format("HH:mm") || "");
              }}
              className={`w-full p-2 border rounded ant-picker-input mb-2 ${formErrors.reagendamentoDataHora ? "border-red-500" : ""
                }`}
              // disable
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
            />
            {formErrors.reagendamentoDataHora && (
              <p className="text-red-500">
                Por favor, selecione uma data e hora válida.
              </p>
            )}
            {formattedStartDate && formattedStartMinutes && (
              <AddToCalendarButton
                name={`Reagendamento da lead - ${additionalData.username}`}
                startDate={formattedStartDate}
                startTime={formattedStartMinutes}
                endTime={addtime}
                language="pt"
                listStyle="modal"
                options={["Apple", "Google", "Yahoo", "iCal", "Outlook.com"]}
                description={`Entrar em contacto com a lead [url]http://localhost:3000/simulacoes/${formData.referencia}[url]`}
                timeZone="UTC"
                trigger="hover"
              ></AddToCalendarButton>
            )}
          </div>
        )}

        {formData.resultadoTratamento === "Fechou apólice" && (
          <div className="mb-4">
            <label className="block mb-2">Nº Apólice:</label>
            <input
              type="text"
              name="numeroApolices"
              value={formData.numeroApolices}
              onChange={handleInputChange}
              className={`w-full p-2 border rounded ${formErrors.numeroApolices ? "border-red-500" : ""
                }`}
            />
            {formErrors.numeroApolices && (
              <p className="text-red-500">
                Por favor, introduza pelo menos um número da apólice.
              </p>
            )}
          </div>
        )}

        {/* Adicionando a opção para selecionar simulações fechadas */}
        {/* {formData.resultadoTratamento === "Fechou apólice" &&
          additionalData.saleSim && (
            <div className="mb-4">
              <label className="block mb-2">Simulações Fechadas:</label>
              <Select
                mode="multiple"
                placeholder="Selecione as simulações fechadas"
                value={formData.fechouSimulacoes}
                onChange={(value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    fechouSimulacoes: value,
                  }));
                }}
                className={`w-full p-2 border rounded`}
              >
                {additionalData.saleSim.data.map((simData) => (
                  <Select.Option key={simData.id} value={simData.id.toString()}> */}
                    {/* tipo de simulacao - nome - award */}
                    {/* {
                      simData.attributes.tipo_simulacao.data.attributes
                        .simulador
                    }
                    - {simData.attributes.nome} -{" "}
                    {simData.attributes.dados_simulacao.award
                      ? simData.attributes.dados_simulacao.award + "€/mês"
                      : simData.attributes.dados_simulacao.capital + "€"}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )} */}
        <div className="mb-4">
          <label className="block mb-2">Observações:</label>
          <input
            type="text"
            name="texto"
            required
            value={formData2.texto}
            onChange={handleInputChange2}
            className={`w-full p-2 border rounded`}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default LeadReport;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { getToken, removeToken } from "../../api/Auth/helpers";
import { BellOutlined, UserOutlined } from "@ant-design/icons";
import { Popover, Avatar, Button, Menu, Layout, Badge } from "antd";
import logo from "../../assets/img/logo192.png";
import { FiLogOut } from "react-icons/fi";
import NotificationComponent from "../notifications";
import "../notifications/not.css";

const { Header } = Layout;

const AppHeader: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const handleNotificationsVisibility = (
    visible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setNotificationsVisible(visible);
  };

  // Simula o fetch de notificações para verificar se há não lidas
  useEffect(() => {
    const checkUnreadNotifications = async () => {
      const token = getToken();
      if (user?.id) {
        try {
          const response = await fetch(
            `https://strapi.realbusinesscenter.wt.pt/api/notification-users?filters[user][id][$eq]=${user.id}&filters[read][$eq]=false&populate=*`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            const nots = data.data;
            setHasUnreadNotifications(nots && nots.length > 0);
            // also if there are unread notifications send browser notification
            if (nots && nots.length > 0) {
              const notification = new Notification(
                "You have unread notifications!",
                {
                  body: "You have unread notifications!",
                  icon: "https://strapi.realbusinesscenter.wt.pt/uploads/logo512_bbac297d2f.png",
                }
              );
              notification.onclick = () => {
                handleNotificationsVisibility(true);
              };
            }
          }
        } catch (error) {
          console.error("Error fetching unread notifications:", error);
        }
      }
    };

    checkUnreadNotifications();
  }, [user?.id]); // Dependência para recarregar quando o usuário muda

  // quero perguntar ao utilizador se aceita as notificacoes do browser
  useEffect(() => {
    if ('Notification' in window && Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // save on db -> to implement
        } else {
          // save on db -> to implement
        }
      });
    }
  }, []);

  function handleLogout() {
    removeToken();
    // remove every local and session storage
    localStorage.clear();
    sessionStorage.clear();

    // navigate to dashboard
    navigate("/");
    // href login
    window.location.href = "/login";
  }

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        isMenuOpen
      ) {
        closeMenu();
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isMenuOpen]);

  const getUsername = () => {
    return user?.username || "Guest";
  };

  const userMenuDropdown = getToken() ? (
    <Menu mode="vertical" style={{ width: 130 }} onClick={() => closeMenu()}>
      <Menu.Item key="profile">
        <Link to="/profile">{getUsername()}</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        {/* remake the div so the text inside stays by the side of the icon */}
        <div className="flex items-center">
          <p className="text-red-500 flex items-center">
            <FiLogOut className="mr-2" /> Logout
          </p>
        </div>
      </Menu.Item>
    </Menu>
  ) : (
    <Menu
      mode="vertical"
      style={{ width: 180 }}
      onClick={() => {
        // window.location reload
        window.location.reload();
        closeMenu();
      }}
    >
      <Menu.Item key="login">
        <Button type="link">Entrar</Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <Header className="header bg-dark-color">
        <div className="flex items-center justify-between">
          <Link
            to="/"
            className="text-white text-xl font-bold flex items-center space-x-2"
          >
            <img src={logo} alt="logo" className="w-8 h-8 mr-2" />
            Plataforma automática de leads
          </Link>
          <div className="flex items-center space-x-4" ref={menuRef}>
            <span className="text-white text-xl font-bold">
              Bem-vindo,{" "}
              <span className="text-blue-500 ml-1">{getUsername()}</span>
            </span>
            <Popover
              content={<NotificationComponent />}
              title="Notificações"
              trigger="click"
              open={notificationsVisible}
              onOpenChange={setNotificationsVisible}
              placement="bottomRight"
              overlayClassName="notification-popover"
            >
              <Badge dot={hasUnreadNotifications}>
                <BellOutlined className="text-white text-xl cursor-pointer" />
              </Badge>
            </Popover>
            <Avatar
              size="large"
              icon={<UserOutlined />}
              className="cursor-pointer"
              onClick={toggleMenu}
            />
            {isMenuOpen && (
              <div className="absolute right-16 top-12 bg-white shadow-xl">
                {userMenuDropdown}
              </div>
            )}
          </div>
        </div>
      </Header>
    </Layout>
  );
};

export default AppHeader;

import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, message, Spin, DatePicker, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { deleteRelatorio } from "../../api/Backend";
import axios from "axios";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_PT";
import { AiOutlineEye } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { API, BEARER } from "../../api/Auth/constant";
import { getToken } from "../../api/Auth/helpers";
import { debounce } from "lodash";
import { RiRefreshLine } from "react-icons/ri";

const { Search } = Input;

interface Relatorio {
  id: number;
  attributes: {
    estado: string;
    simulacao: {
      data: {
        attributes: {
          nome: string;
          uuid: string;
          tipo_simulacao: {
            data: {
              attributes: {
                slug: string;
              };
            };
          };
        };
      };
    };
    createdAt: string;
  };
}

const SvIp = process.env.REACT_APP_SERVER_IP;
const backendApi = process.env.REACT_APP_BACKEND_API;
const { RangePicker } = DatePicker;

const ReportsPage: React.FC = () => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const authToken = getToken();
  const { user } = useAuthContext();
  const [relatorios, setRelatorios] = useState<Relatorio[]>([]);
  const [totalReports, setTotalReports] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [searchText, setSearchText] = useState<string>("");
  const handleRefresh = debounce(async () => {
    // Se houver um intervalo de datas, aplica o filtro e busca os relatórios
    const start = dateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const end = dateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    await fetchRelatorios(start, end); // Chama a função de busca de relatórios
  }, 300); // 300ms de debounce


  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleExportToExcel = async () => {
    setLoading(true);

    // Define variáveis com username, start date e end date
    const username = user?.username;
    const id = user?.id;
    // startdate e enddate para o json
    const start = dateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const end = dateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    // Verifica se as datas estão definidas
    const startDate = start
      ? moment(start).format("DD-MM-YYYY")
      : moment().startOf("month").format("DD-MM-YYYY");
    const endDate = end
      ? moment(end).format("DD-MM-YYYY")
      : moment().endOf("month").format("DD-MM-YYYY");


    // Cria o objeto JSON com os dados para o relatório
    const exportData = {
      username,
      id,
      startDate,
      endDate,
    };

    const json = JSON.stringify(exportData);

    try {
      // Passo 1: Solicita a chave primeiro
      const keyResponse = await axios.get(
        `${backendApi}/get-key.php`,
        {
          params: {
            code: "964587147", // Envia o código como parâmetro de consulta
          },
          headers: {
            "Content-Type": "application/json",
            "x-server-ip-for": SvIp,
          },
        }
      );

      const { key } = keyResponse.data;

      if (!key) {
        message.error("Erro ao obter a chave de segurança!");
        setLoading(false);
        return;
      }

      // Passo 2: Faz a requisição para gerar o relatório com a chave obtida
      const response = await axios.post(
        `${backendApi}/generate-report.php`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
            "x-server-ip-for": SvIp,
            "x-api-key": key, // Adiciona a chave recebida
          },
        }
      );

      // Verifica a resposta para erros
      if (response.data.message === "ERRO") {
        message.warning("Erro ao exportar o relatório, sem dados para exportar!");
        setLoading(false);
        return;
      } else {
        // Aqui é onde você pega o link de download da resposta
        const downloadLink = response.data.download_link;

        // Inicia o download do arquivo Excel
        const link = document.createElement("a");
        link.href = downloadLink;
        link.setAttribute("download", "relatorio.xlsx"); // Nome do arquivo a ser baixado
        document.body.appendChild(link);
        link.click(); // Clica no link para iniciar o download
        link.remove(); // Remove o link do DOM após o download

        message.success("Relatório exportado com sucesso!");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Erro ao exportar relatório!");
      setLoading(false);
    }
  }


  const getEstadoColor = (estado: string): string => {
    switch (estado) {
      case "Fechada": return "#ff4d4f";
      case "Aberta": return "#ffa940";
      case "Entregue": return "#73d13d";
      case "Reagendada": return "#9254de";
      case "Cancelada": return "#595959";
      case "Falsa": return "#ffec3d";
      case "Repetida": return "#40a9ff";
      case "Outro": return "#ff7a45";
      case "Contacto Errado": return "#2596be";
      case "Não atendeu": return "#e28743";
      case "Não vai avançar": return "#abdbe3";
      default: return "#000000";
    }
  };

  const fetchRelatorios = async (startDate?: string, endDate?: string) => {
    try {
      let params1: Record<string, any> = {};
      setLoading(true);
      const offset = (currentPage - 1) * pageSize;

      params1 = {
        "pagination[start]": offset,
        "pagination[limit]": pageSize,
        "sort[0]": "id:desc",
        "populate[]": [
          "simulacao",
          "simulacao.user",
          "simulacao.user.equipa",
          "simulacao.user.equipa.zona",
          "simulacao.tipo_simulacao",
          "simulacao.observacoes",
        ],
      };
      // Adiciona filtros baseados no nível do usuário
      switch (user?.tipo?.level) {
        case 3: // Nível intermediário (por exemplo, Diretor)
          params1["filters[simulacao][user][equipa][zona][users_permissions_user][id][$eq]"] = user?.id;
          break;
        case 4: // Outro nível intermediário (por exemplo, Gestor)
          params1["filters[simulacao][user][equipa][users_permissions_user][id][$eq]"] = user?.id;
          break;
        case 5: // Outro nível intermediário (por exemplo, Gestor)
          params1["filters[simulacao][user][id][$eq]"] = user?.id;
          break;
        default: // Níveis 0, 1, 2 ou outros casos (por exemplo, Agente)
          break;
      }
      if (startDate && endDate && !searchText) {
        params1["filters[createdAt][$gte]"] = startDate;
        params1["filters[createdAt][$lte]"] = endDate;
      }
      if (searchText) {
        params1["_q"] = searchText;
      }

      const response = await axios.get(`${API}/relatorios`, {
        params: params1,
        headers: { Authorization: `${BEARER} ${authToken}` },
      });

      if (Array.isArray(response.data.data)) {
        setRelatorios(response.data.data);
        setTotalReports(response.data.meta.pagination.total);
      } else {
        console.error("Dados retornados não são um array:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar relatórios:", error);
      message.error("Erro ao buscar relatórios.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      const fetchData = async () => {
        let start: string | undefined = dateRange[0]
          ? dateRange[0].startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : undefined;
        let end: string | undefined = dateRange[1]
          ? dateRange[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : undefined;

        // Verifica se as datas estão definidas
        if (!start && !end) {
          start = moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
          end = moment().endOf("month").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }

        await fetchRelatorios(start, end);
      };

      fetchData();
    }
  }, [dateRange, currentPage, pageSize, user, searchText]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }

    // Chamar fetchRelatorios com as datas atuais
    const start = dateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const end = dateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    fetchRelatorios(start, end);
  };

  const handleDateChange = (dates: any) => {
    setCurrentPage(1);
    setDateRange(dates && dates.length === 2 ? dates : []);
  };

  const columns = [

    {
      title: "ID",
      dataIndex: [
        "id"
      ],
      key: "id",
    },
    {
      title: "Agente",
      dataIndex: [
        "attributes",
        "simulacao",
        "data",
        "attributes",
        "user",
        "data",
        "attributes",
        "username",
      ],
      key: "agente",
    },
    {
      title: "Nome",
      dataIndex: ["attributes", "simulacao", "data", "attributes", "nome"],
      key: "name",
      // deverá mostrar até ao maximo de 24 caracteres
      render: (text: string, record: any) => (
        <span>{text.length > 14 ? text.substring(0, 14) + "..." : text}</span>
      ),
    },
    {
      title: "ID Simulação",
      dataIndex: ["attributes", "simulacao", "data", "uuidid"],
      key: "id",
      render: (text: string, record: any) => (
        <a
          href={`/${record.attributes.simulacao.data.attributes.tipo_simulacao.data.attributes.slug}/${record.attributes.simulacao.data.attributes.uuid}`}
        >
          {record.attributes.simulacao.data.attributes.uuid}
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: ["attributes", "estado"],
      key: "estado",
      render: (text: string, record: any) => (
        <div className="flex flex-col items-center gap-2">
          <span
            className="text-xs"
            style={{
              backgroundColor: getEstadoColor(record.attributes.estado),
              borderRadius: "5px",
              padding: "5px",
              color: "black",
            }}
          >
            {text}
          </span>
          {record.attributes.estado === "Reagendada" &&
            record.attributes.data_reagendamento && (
              <>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </>
            )}
        </div>
      ),
    },
    {
      title: "Data de Criação",
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",
      render: (text: string) => moment(text).format("DD/MM/YYYY"), // Format the date
    },
    {
      title: "Relatório",
      key: "action",
      render: (text: string, record: any) => (
        <div>
          <Button
            type="primary"
            className="bg-blue-500 hover:bg-blue-600/20"
            onClick={() => navigate(`/relatorio/${record.id}`)}
          >
            <AiOutlineEye />
          </Button>

          <Button
            type="primary"
            className="ml-2 bg-red-500 hover:bg-red-600"
            onClick={() => {
              const confirmation = window.confirm(
                `Tem a certeza que quer apagar o relatório de ${record.attributes.simulacao.data.attributes.nome}?`
              );
              if (confirmation) {
                deleteRelatorio(record.id);
              }
            }}
          >
            <FiTrash2 />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="flex justify-center bg-gray-100">
      {loading ? (
        <div className="flex items-center justify-center w-full h-screen bg-gray-100">
          <Spin size="large" />
        </div>
      ) : (
        <div className="items-start w-full p-12 px-4 mx-auto sm:px-6 lg:px-8">
          <div className="p-4 mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="mb-4">
              <Button
                type="primary"
                className="mr-4 bg-blue-500 hover:bg-blue-600/20"
                onClick={handleRefresh}
              >
                <RiRefreshLine />
              </Button>
              <RangePicker
                locale={locale} // Use a localização correta aqui
                className="mr-4"
                onChange={handleDateChange}
                // se tiver definido define o value
                value={
                  dateRange.length === 2
                    ? [dateRange[0], dateRange[1]]
                    : undefined
                }
              />
              {/* caso seja level <= 4 mostrar o botao */}
              {user?.tipo?.level <= 4 && (
                <Button
                  type="primary"
                  className="ml-4 bg-green-500 hover:bg-green-600/20"
                  onClick={handleExportToExcel}
                >
                  Exportar para Excel
                </Button>
              )}
              <Search
                placeholder="Pesquisar"
                onSearch={handleSearch}
                style={{ width: 300, marginLeft: 8, marginTop: 5 }}
              />
            </div>

            <Table
              columns={columns}
              dataSource={relatorios}
              rowKey={(record) => record.id}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalReports,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total} relatórios`,

                onChange: handlePageChange,
              }}
              scroll={{ y: 'calc(100vh - 400px)' }} // Permite o scroll vertical
              onRow={(record) => ({
                onClick: (event) => {
                  // Verificar se o elemento clicado é um botão ou um elemento filho de um botão
                  if ((event.target as Element).closest("button")) {
                    // Se for um botão, evita a execução da navegação
                    return;
                  }
                navigate(`/relatorio/${record.id}`)

                },
              })}

            />
          </div>
        </div>
      )}
    </div>
  );


};
export default ReportsPage;
